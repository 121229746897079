import { writable } from "../_snowpack/pkg/svelte/store.js";

function createState() {
  const defaultState = {
    showAgeGate: true,
    age: null,
    jumpTimeInSeconds: 0,
  };
  const { subscribe, set, update } = writable(defaultState);

  return {
    subscribe,
    triggerVideo: () =>
      update((state) => ({
        ...state,
        showAgeGate: false,
      })),
    reset: () => set(defaultState),
    update,
    set,
  };
}

export const state = createState();
