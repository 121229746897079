import './App.svelte.css';
/* src/App.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { state } from "./state.js";
import PopupBox from "./Components/PopupBox.svelte.js";
import PopupButton from "./Components/PopupButton.svelte.js";
import VideoViewer from "./Components/Video.svelte.js";
import AgeVerification from "./Components/AgeVerification.svelte.js";

function create_else_block(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "class", "preview-image svelte-1pxwcca");
			if (img.src !== (img_src_value = "images/blurredVideo.jpg")) attr(img, "src", img_src_value);
			attr(img, "alt", "blurred video");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (19:6) {#if !$state.showAgeGate && !$state.showDeniedMessage}
function create_if_block_1(ctx) {
	let videoviewer;
	let current;

	videoviewer = new VideoViewer({
			props: {
				videoStartTimeInSeconds: /*$state*/ ctx[0].jumpTimeInSeconds
			}
		});

	return {
		c() {
			create_component(videoviewer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(videoviewer, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const videoviewer_changes = {};
			if (dirty & /*$state*/ 1) videoviewer_changes.videoStartTimeInSeconds = /*$state*/ ctx[0].jumpTimeInSeconds;
			videoviewer.$set(videoviewer_changes);
		},
		i(local) {
			if (current) return;
			transition_in(videoviewer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(videoviewer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(videoviewer, detaching);
		}
	};
}

// (29:6) {#if $state.showAgeGate}
function create_if_block(ctx) {
	let popupbox;
	let current;

	popupbox = new PopupBox({
			props: {
				title: "AGE VERIFICATION",
				description: "You must be 19 years of age or older to watch this video.",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(popupbox.$$.fragment);
		},
		m(target, anchor) {
			mount_component(popupbox, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(popupbox.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(popupbox.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(popupbox, detaching);
		}
	};
}

// (30:8) <PopupBox           title="AGE VERIFICATION"           description="You must be 19 years of age or older to watch this video."         >
function create_default_slot(ctx) {
	let ageverification;
	let current;
	ageverification = new AgeVerification({});

	return {
		c() {
			create_component(ageverification.$$.fragment);
		},
		m(target, anchor) {
			mount_component(ageverification, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(ageverification.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(ageverification.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(ageverification, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div1;
	let div0;
	let current_block_type_index;
	let if_block0;
	let t;
	let current;
	const if_block_creators = [create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*$state*/ ctx[0].showAgeGate && !/*$state*/ ctx[0].showDeniedMessage) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	let if_block1 = /*$state*/ ctx[0].showAgeGate && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");
			if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			attr(div0, "class", "video-container svelte-1pxwcca");
			attr(div1, "class", "page");
			attr(div2, "class", "App svelte-1pxwcca");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, div0);
			if_blocks[current_block_type_index].m(div0, null);
			append(div0, t);
			if (if_block1) if_block1.m(div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block0 = if_blocks[current_block_type_index];

				if (!if_block0) {
					if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block0.c();
				} else {
					if_block0.p(ctx, dirty);
				}

				transition_in(if_block0, 1);
				if_block0.m(div0, t);
			}

			if (/*$state*/ ctx[0].showAgeGate) {
				if (if_block1) {
					if (dirty & /*$state*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div0, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if_blocks[current_block_type_index].d();
			if (if_block1) if_block1.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $state;
	component_subscribe($$self, state, $$value => $$invalidate(0, $state = $$value));

	const onResetPressed = () => {
		// Reset the app to the initial state
		state.reset();
	};

	return [$state];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;