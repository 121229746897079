import './PopupBox.svelte.css';
/* src/Components/PopupBox.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	create_slot,
	detach,
	element,
	exclude_internal_props,
	init,
	insert,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out,
	update_slot
} from "../../_snowpack/pkg/svelte/internal.js";

function create_if_block(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			attr(div, "class", "content svelte-ssj50m");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[3], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let div7;
	let div6;
	let div0;
	let t0;
	let div5;
	let div4;
	let div1;
	let t1;
	let t2;
	let div3;
	let div2;
	let t3;
	let t4;
	let current;
	let if_block = /*SLOTS*/ ctx[2] !== undefined && create_if_block(ctx);

	return {
		c() {
			div7 = element("div");
			div6 = element("div");
			div0 = element("div");
			t0 = space();
			div5 = element("div");
			div4 = element("div");
			div1 = element("div");
			t1 = text(/*title*/ ctx[0]);
			t2 = space();
			div3 = element("div");
			div2 = element("div");
			t3 = text(/*description*/ ctx[1]);
			t4 = space();
			if (if_block) if_block.c();
			attr(div0, "class", "redBar svelte-ssj50m");
			attr(div1, "class", "title-text svelte-ssj50m");
			attr(div2, "class", "description-text svelte-ssj50m");
			attr(div3, "class", "width-limiter svelte-ssj50m");
			attr(div4, "class", "content-parent-centerer svelte-ssj50m");
			attr(div5, "class", "content-parent svelte-ssj50m");
			attr(div6, "class", "popup-box svelte-ssj50m");
			attr(div7, "class", "popup-box-container svelte-ssj50m");
		},
		m(target, anchor) {
			insert(target, div7, anchor);
			append(div7, div6);
			append(div6, div0);
			append(div6, t0);
			append(div6, div5);
			append(div5, div4);
			append(div4, div1);
			append(div1, t1);
			append(div4, t2);
			append(div4, div3);
			append(div3, div2);
			append(div2, t3);
			append(div4, t4);
			if (if_block) if_block.m(div4, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*title*/ 1) set_data(t1, /*title*/ ctx[0]);
			if (!current || dirty & /*description*/ 2) set_data(t3, /*description*/ ctx[1]);
			if (/*SLOTS*/ ctx[2] !== undefined) if_block.p(ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div7);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { title } = $$props;
	let { description } = $$props;
	const SLOTS = $$props.$$slots;

	$$self.$$set = $$new_props => {
		$$invalidate(5, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		if ("title" in $$new_props) $$invalidate(0, title = $$new_props.title);
		if ("description" in $$new_props) $$invalidate(1, description = $$new_props.description);
		if ("$$scope" in $$new_props) $$invalidate(3, $$scope = $$new_props.$$scope);
	};

	$$props = exclude_internal_props($$props);
	return [title, description, SLOTS, $$scope, slots];
}

class PopupBox extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { title: 0, description: 1 });
	}
}

export default PopupBox;