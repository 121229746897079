import './PopupButton.svelte.css';
/* src/Components/PopupButton.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	detach,
	element,
	exclude_internal_props,
	init,
	insert,
	is_function,
	listen,
	noop,
	safe_not_equal,
	set_data,
	text as text_1
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let button;
	let t;
	let button_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			t = text_1(/*text*/ ctx[0]);
			attr(button, "class", button_class_value = "button " + /*$$props*/ ctx[3].class + " svelte-19ecbb3");
			button.disabled = /*disabled*/ ctx[2];
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*onClickCallback*/ ctx[1])) /*onClickCallback*/ ctx[1].apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			if (dirty & /*text*/ 1) set_data(t, /*text*/ ctx[0]);

			if (dirty & /*$$props*/ 8 && button_class_value !== (button_class_value = "button " + /*$$props*/ ctx[3].class + " svelte-19ecbb3")) {
				attr(button, "class", button_class_value);
			}

			if (dirty & /*disabled*/ 4) {
				button.disabled = /*disabled*/ ctx[2];
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { text } = $$props;
	let { onClickCallback } = $$props;
	let { disabled = false } = $$props;

	$$self.$$set = $$new_props => {
		$$invalidate(3, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		if ("text" in $$new_props) $$invalidate(0, text = $$new_props.text);
		if ("onClickCallback" in $$new_props) $$invalidate(1, onClickCallback = $$new_props.onClickCallback);
		if ("disabled" in $$new_props) $$invalidate(2, disabled = $$new_props.disabled);
	};

	$$props = exclude_internal_props($$props);
	return [text, onClickCallback, disabled, $$props];
}

class PopupButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { text: 0, onClickCallback: 1, disabled: 2 });
	}
}

export default PopupButton;