import './AgeVerification.svelte.css';
/* src/Components/AgeVerification.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { state } from "../state.js";
import PopupButton from "./PopupButton.svelte.js";

function create_fragment(ctx) {
	let div1;
	let div0;
	let label;
	let input;
	let span;
	let t1;
	let popupbutton;
	let current;
	let mounted;
	let dispose;

	popupbutton = new PopupButton({
			props: {
				text: "Confirm & Proceed",
				onClickCallback: /*onConfirmPressed*/ ctx[1],
				disabled: !/*checked*/ ctx[0]
			}
		});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			label = element("label");
			input = element("input");
			span = element("span");
			span.textContent = "I confirm that I am 19 years old or older.";
			t1 = space();
			create_component(popupbutton.$$.fragment);
			attr(input, "class", "checkbox-style svelte-soewr7");
			attr(input, "type", "checkbox");
			attr(span, "class", "svelte-soewr7");
			attr(label, "class", "svelte-soewr7");
			attr(div0, "class", "checkbox-parent svelte-soewr7");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, label);
			append(label, input);
			input.checked = /*checked*/ ctx[0];
			append(label, span);
			append(div1, t1);
			mount_component(popupbutton, div1, null);
			current = true;

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*checked*/ 1) {
				input.checked = /*checked*/ ctx[0];
			}

			const popupbutton_changes = {};
			if (dirty & /*checked*/ 1) popupbutton_changes.disabled = !/*checked*/ ctx[0];
			popupbutton.$set(popupbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(popupbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(popupbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(popupbutton);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let checked = false;

	// State functions
	const onConfirmPressed = () => {
		if (checked) {
			state.triggerVideo();
		}
	};

	function input_change_handler() {
		checked = this.checked;
		$$invalidate(0, checked);
	}

	return [checked, onConfirmPressed, input_change_handler];
}

class AgeVerification extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default AgeVerification;