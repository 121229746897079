/* src/Components/Video.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	action_destroyer,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount, onDestroy } from "../../_snowpack/pkg/svelte.js";
import "../../_snowpack/pkg/shaka-player/dist/controls.css";
import shaka from "../../_snowpack/pkg/shaka-player/dist/shaka-player.ui.js";

function create_fragment(ctx) {
	let div;
	let video_1;
	let video_action;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			video_1 = element("video");
			attr(video_1, "class", "shaka-video");
			set_style(video_1, "width", "100%");
			set_style(video_1, "height", "100%");
			attr(div, "class", "video-container");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, video_1);
			/*div_binding*/ ctx[4](div);

			if (!mounted) {
				dispose = action_destroyer(video_action = /*video*/ ctx[1].call(null, video_1));
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			/*div_binding*/ ctx[4](null);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const videoConfig = {
		src: "video/dash/bc-cannabis-stores-in-your-community-dash.mpd",
		type: "application/dash+xml"
	};

	shaka.polyfill.installAll();

	// Bindings
	let videoContainer;

	let videoNode;
	let videoPlayer;
	let { videoStartTimeInSeconds = 0 } = $$props;

	const loadVideo = () => {
		if (videoContainer && videoNode && !videoPlayer) {
			if (shaka.Player.isBrowserSupported()) {
				$$invalidate(3, videoPlayer = new shaka.Player(videoNode));
				const ui = new shaka.ui.Overlay(videoPlayer, videoContainer, videoNode);

				ui.configure({
					controlPanelElements: [
						"time_and_duration",
						"play_pause",
						"mute",
						"volume",
						"spacer",
						"fullscreen",
						"overflow_menu"
					],
					overflowMenuButtons: ["quality"]
				});

				const controls = ui.getControls();

				videoPlayer.load(videoConfig.src).then(() => {
					videoNode.play();
				}).catch(e => {
					console.error(e);
				});
			} else {
				console.error("Browser not supported!");
			}
		}
	};

	const destroyVideo = () => {
		videoPlayer.destroy();
	};

	onMount(() => loadVideo());
	onDestroy(() => destroyVideo());

	const video = node => {
		if (node && !videoNode) {
			videoNode = node;
			loadVideo();
		}

		return {
			destroy() {
				destroyVideo();
			}
		};
	};

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			videoContainer = $$value;
			$$invalidate(0, videoContainer);
		});
	}

	$$self.$$set = $$props => {
		if ("videoStartTimeInSeconds" in $$props) $$invalidate(2, videoStartTimeInSeconds = $$props.videoStartTimeInSeconds);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*videoPlayer, videoStartTimeInSeconds*/ 12) {
			// When videoStartTime changes, jump to that time in seconds
			$: {
				if (videoPlayer && videoStartTimeInSeconds !== 0) {
					videoPlayer.setPlaybackStartTime(videoStartTimeInSeconds);
				}
			}
		}
	};

	return [videoContainer, video, videoStartTimeInSeconds, videoPlayer, div_binding];
}

class Video extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { videoStartTimeInSeconds: 2 });
	}
}

export default Video;